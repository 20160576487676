
import Vue from "vue";
import ListTile from "@/components/molecules/lists/ListTile.vue";
import { openPresetSelectorDialog } from "@/lib/dialog";
import { MaterialItem, MaterialItemEnum } from "@/lib/models";
import { S3Client } from "@/lib/clients";
import { Dialog } from "@/lib/utils";
import ImageCropDialog, { ImageCropDialogProps } from "@/components/ui/ImageCropDialog.vue";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedOutlineIcon from "icons/CheckboxMarkedOutline.vue";
import LoadImage from "@/components/atoms/images/LoadImage.vue";

interface Data {
  src: string | null;
}

interface Methods {
  onPresetButtonClick: () => void;
  onClearButtonClick: () => void;
  onCheck: () => void;
  selectedImageFile: (event: Event) => void;
  createImage: (file: File) => void;
  openPresetDialog: () => void;
  setImageUrl: (path?: string) => Promise<void>;
}

interface Computed {
  isPreset: boolean;
  isRequired: boolean;
  isCheck: boolean;
  // "singleline-text" | "multiline-text" | "image-square" | "image-portrait" | "image-landscape"
  inputType: MaterialItemEnum;
  title?: string;
  value?: string;
  image?: string;
  singlelineLabel(valueLimit: number): string;
  singlelinePlaceholder: string;
  multilineLabel: string;
  multilinePlaceholder: string;
}

interface Props {
  titleLimit: number;
  valueLimit: number;
  item: MaterialItem;
  isBasic: boolean;
  isImageOnly: boolean;
  checkedItems: MaterialItem[];
  updateItem: (item: MaterialItem) => void;
  updateCheckedItems: (item: MaterialItem) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ListTile, CheckboxBlankOutlineIcon, CheckboxMarkedOutlineIcon, LoadImage },

  data() {
    return {
      src: null,
    };
  },

  props: {
    titleLimit: {
      type: Number,
      default: 30,
    },
    valueLimit: {
      type: Number,
      default: 300,
    },
    item: Object as Vue.PropType<MaterialItem>,
    isBasic: {
      type: Boolean,
      default: false,
    },
    isImageOnly: {
      type: Boolean,
      default: false,
    },
    checkedItems: Array,
    updateItem: Function,
    updateCheckedItems: Function,
  },

  computed: {
    inputType() {
      const { item } = this;
      return item.type;
    },
    title: {
      get() {
        const { item } = this;
        return item.title;
      },
      set(title) {
        const { item, updateItem } = this;
        const updatedItem = {
          ...item,
          title,
        };
        updateItem(updatedItem);
      },
    },
    value: {
      get() {
        const { item } = this;
        return item.value;
      },
      set(value) {
        const { item, updateItem } = this;
        const updatedItem = {
          ...item,
          value,
        };
        updateItem(updatedItem);
      },
    },
    image: {
      get() {
        const { item } = this;
        return item.imagePath;
      },
      async set(image) {
        const { item, setImageUrl, updateItem } = this;
        const updatedItem = {
          ...item,
          imagePath: image,
        };
        await setImageUrl(image);
        updateItem(updatedItem);
      },
    },
    singlelineLabel() {
      return (valueLimit) => {
        const { item, inputType } = this;

        switch (inputType) {
          case MaterialItemEnum.TEXT_SINGLELINE:
            return `${item.title}（${valueLimit}文字以内）`;

          default:
            return `項目名（${valueLimit}文字以内）`;
        }
      };
    },
    singlelinePlaceholder() {
      const { item, inputType } = this;

      switch (inputType) {
        case MaterialItemEnum.TEXT_SINGLELINE:
          return `${item.title}を入力してください`;

        default:
          return "項目名を入力してください";
      }
    },
    multilineLabel() {
      return "内容（300文字以内）";
    },
    multilinePlaceholder() {
      return "内容を入力してください";
    },
    isPreset() {
      const { image } = this;

      if (!image) {
        return false;
      }

      if (image.startsWith("shared")) {
        return true;
      }

      return false;
    },
    isRequired() {
      const { item } = this;
      return item.isRequired;
    },
    isCheck() {
      const { item, checkedItems } = this;
      return checkedItems.some((checkedItem) => checkedItem.id === item.id);
    },
  },

  methods: {
    onPresetButtonClick() {
      const { openPresetDialog } = this;
      openPresetDialog();
    },
    onClearButtonClick() {
      this.image = undefined;
    },
    onCheck() {
      const { item, updateCheckedItems } = this;
      if (updateCheckedItems) {
        updateCheckedItems(item);
      }
    },
    selectedImageFile(event) {
      const { target } = event;
      if (target instanceof HTMLInputElement && target.files && target.files.length > 0) {
        const { createImage } = this;
        createImage(target.files[0]);
      }
    },
    createImage(file) {
      const { item } = this;
      const reader = new FileReader();
      const positiveCallback = (data?: string) => {
        this.image = data;
      };
      const onload = async (event: ProgressEvent<FileReader>) => {
        const { target } = event;
        if (!target) {
          return;
        }

        if (!target.result) {
          return;
        }

        const image = target.result;
        const cropDialog = new Dialog(ImageCropDialog);
        const params: ImageCropDialogProps = {
          title: "画像の切り抜き",
          content: "追加したアイテムに合ったアスペクト比に切り抜いてください",
          ratioX: item.ratioX ?? 1,
          ratioY: item.ratioY ?? 1,
          image,
          positiveCallback,
        };

        cropDialog.show(params);
      };
      reader.onload = onload;
      reader.readAsDataURL(file);
    },
    async openPresetDialog() {
      const response = await openPresetSelectorDialog();

      if (!response) {
        return;
      }

      this.image = response.key;
    },
    async setImageUrl(path?: string) {
      if (!path) {
        this.src = null;
        return;
      }

      if (path.includes("data:")) {
        this.src = path;
        return;
      }

      this.src = await new S3Client().getImagePath(path);
    },
  },
});
