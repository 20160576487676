
import Vue, { PropType } from "vue";
import MaterialDetail from "@/components/organisms/materials/MaterialDetail.vue";
import { NovelMaterial } from "@/lib/models";

interface Data {}

interface Methods {
  onClickOutSide: () => void;
  onNegativeClick: () => void;
}

interface Props {
  title: string;
  material: NovelMaterial;
  negative?: string;
  negativeCallback?: () => void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: { MaterialDetail },

  data() {
    return {
      canvas: undefined,
    };
  },

  props: {
    title: String,
    material: Object as PropType<NovelMaterial>,
    negative: {
      type: String,
      default: "閉じる",
    },
    negativeCallback: Function,
  },

  created() {},

  methods: {
    onNegativeClick() {
      const { negativeCallback } = this;

      if (negativeCallback) {
        negativeCallback();
      }

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
  },
});

export type MaterialPreviewDialogProps = Props;
