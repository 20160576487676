
import Vue, { PropType } from "vue";
import ButtonRound from "@/components/atoms/ButtonRound.vue";
import LoadImage from "@/components/atoms/images/LoadImage.vue";
import { LayoutEnum, MaterialItem, NovelMaterial } from "@/lib/models";

interface Methods {
  isPreset: (image: string) => boolean;
}

interface Comupted {
  materialId: string | null;
  novelMaterial: NovelMaterial | null;
  items: MaterialItem[];
  layoutType: string | null;
}

interface Props {
  preview?: NovelMaterial;
}

export default Vue.extend<unknown, Methods, Comupted, Props>({
  components: { ButtonRound, LoadImage },

  props: {
    preview: Object as PropType<NovelMaterial>,
  },

  computed: {
    materialId() {
      const { params } = this.$route;

      if (params && params.materialId) {
        return params.materialId;
      }

      return null;
    },
    novelMaterial() {
      const { preview, materialId } = this;
      if (preview) {
        return preview;
      }

      if (!materialId) {
        return null;
      }

      const { getters } = this.$store;
      const novelMaterial = getters["materialModule/material"](materialId);
      if (!novelMaterial) {
        return null;
      }

      return novelMaterial;
    },
    items() {
      const { novelMaterial, layoutType } = this;
      if (!novelMaterial || !layoutType) return [];

      const { items } = novelMaterial;
      let basicItemLength = 2;
      switch (layoutType) {
        case LayoutEnum.RESUME:
        case LayoutEnum.ENLARGEMENT:
          basicItemLength = 3;
          break;
        case LayoutEnum.DICTIONARY:
        default:
          break;
      }

      return items.slice(basicItemLength, items.length);
    },
    layoutType() {
      const { novelMaterial } = this;
      if (!novelMaterial) return null;

      return novelMaterial.layout;
    },
  },

  methods: {
    isPreset(image) {
      if (!image) {
        return false;
      }

      if (image.startsWith("shared/")) {
        return true;
      }

      if (image.startsWith("preset:")) {
        return true;
      }

      return false;
    },
  },
});
