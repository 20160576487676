
import Vue from "vue";
import { Coordinates, Cropper, ImageTransforms, VisibleArea } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

interface Data {
  canvas?: HTMLCanvasElement;
}

interface Methods {
  onClickOutSide: () => void;
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onChange: (event: {
    coordinates: Coordinates;
    visibleArea: VisibleArea;
    canvas?: HTMLCanvasElement;
    image: {
      width: number;
      height: number;
      transforms: ImageTransforms;
      src: string | null;
    };
  }) => void;

  defaultSize: (event: {
    visibleArea: VisibleArea;
    imageSize: {
      width: number;
      height: number;
    };
    stencilRatio: {
      minimum: number;
      maximum: number;
    };
    sizeRestrictions: {
      minWidth: number;
      minHeight: number;
      maxWidth: number;
      maxHeight: number;
    };
  }) => void;
}

interface Props {
  title: string;
  content: string;
  ratioX: number;
  ratioY: number;
  image: string | ArrayBuffer;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: (data?: string) => void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: { Cropper },

  data() {
    return {
      canvas: undefined,
    };
  },

  props: {
    title: String,
    content: String,
    ratioX: {
      type: Number,
      default: 1,
    },
    ratioY: {
      type: Number,
      default: 1,
    },
    image: {
      type: String,
      default: "",
    },
    negative: {
      type: String,
      default: "キャンセル",
    },
    negativeCallback: Function,
    positive: {
      type: String,
      default: "切り抜く",
    },
    positiveCallback: Function,
  },

  created() {},

  methods: {
    // event handle

    onNegativeClick() {
      const { negativeCallback } = this;

      if (negativeCallback) {
        negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      const { canvas, positiveCallback } = this;

      let data;

      if (canvas) {
        data = canvas.toDataURL("image/jpeg");
      }

      if (positiveCallback) {
        positiveCallback(data);
      }

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    onChange({ canvas }) {
      this.canvas = canvas;
    },

    // parameter

    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
  },
});

export type ImageCropDialogProps = Props;
