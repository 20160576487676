
import { S3Client } from "@/lib/clients";
import Vue from "vue";

interface Data {
  src: string;
}

interface Methods {
  getImageUrl: () => Promise<void>;
}

interface Props {
  path: string;
  placeholder: string;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  created() {
    const { getImageUrl } = this;
    getImageUrl();
  },
  data() {
    return {
      src: this.placeholder,
    };
  },
  props: {
    path: String,
    placeholder: String,
  },
  methods: {
    async getImageUrl() {
      const { path } = this;

      if (path) {
        this.src = await new S3Client().getImagePath(path);
        return;
      }

      this.src = this.placeholder;
    },
  },
  watch: {
    path() {
      const { getImageUrl } = this;
      getImageUrl();
    },
  },
});
